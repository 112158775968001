<template>
<span class="AllotAuthority">
    <el-button type="text" @click="handleModalShow">
        <el-tooltip placement="top" content="编辑权限">
            <i class="iconfont icon-authority" />
        </el-tooltip>
    </el-button>
    <el-dialog :visible.sync="dialogVisible" title="分配权限" width='800px'
        class="allot-authority-dialog" @close='handleModalClose'>
        <div class="dialog-content">
            <div class="form-item" v-for="item in authList" :key="item.key">
                <template v-if="item.list.length">
                    <div class="key">{{item.name}}:</div>
                    <div class="value">
                        <el-checkbox-group class="authority-item" v-model="item.select" @change="handleSelectChange">
                            <el-checkbox v-for="auth of item.list" :key="auth.id" :label="auth.id">{{auth.name}}</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </template>
            </div>
        </div>
        <div slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
            <el-button type="primary" size="medium" @click="handleConfirm">确定</el-button>
            <el-button class="cancel" size="medium" @click="handleModalClose">取消</el-button>
        </div>
    </el-dialog>
</span>
</template>

<script>
import Core from '@/core';
const AUTH_LIST_TEMP = Core.Const.DISPLAY_TEMP.AUTH_LIST_TEMP
const USER_TYPE = Core.Const.USER_TYPE;
let AUTH_LIST = []
export default {
    name: 'AllotAuthority',
    components: {},
    props: {
        detail: {
            type: Object,
        },
        serviceType: {},
    },
    data() {
        return {
            dialogVisible: false,

            authList: Core.Utils.deepCopy(AUTH_LIST_TEMP),
            authIdsList: [],
        }
    },
    computed: {},
    mounted() {
        let authList = Core.Utils.deepCopy(AUTH_LIST_TEMP)
        console.log('this.serviceType:', this.serviceType)
        if (this.serviceType == 2) {
            authList.forEach((item,index) => {
                if (item.key == 'week') {
                    authList.splice(index,1)
                }
            });
        }
        switch (Core.Data.getUserType()) {
            case USER_TYPE.SCHOOL:
                authList.forEach((item,index) => {
                    if (item.key == 'org') {
                        authList.splice(index,1)
                    }
                });
                break;
        }
        AUTH_LIST = Core.Utils.deepCopy(authList)
        this.authList = authList
    },
    methods: {
        getAuthorityListAll() { // 获取 所有权限
            Core.Api.Authority.authorityListAll().then(res =>{
                // console.log('getAuthorityListAll res:', res)
                let authList = res.list;
                authList.map(auth => {
                    let key = auth.key.split('.')[0];
                    let item = this.authList.find(i => key === i.key);
                    if (item) {
                        item.list.push({ id: auth.id, name: auth.name,});
                    }
                })
                this.getRoleAuthorityList();
            })
        },
        getRoleAuthorityList() { // 获取该角色 已有的 权限
			Core.Api.Authority.roleAuthorityList(this.detail.id).then(res => {
				let authSelectList = res.list;
				let authIdsList = [];
                authSelectList.map(auth => {
                    let key = auth.key.split('.')[0];
                    let item = this.authList.find(i => key === i.key);
                    if (item) {
                        item.select.push(auth.id);
                        authIdsList.push(auth.id)
                    }
                })
                this.authIdsList = authIdsList
                console.log("getRoleAuthorityList this.authList", this.authList)
                console.log("getRoleAuthorityList this.authIdsList", this.authIdsList)
			}).catch(err => {
				console.log("getRoleAuthorityList err:", err)
			})
		},

        handleModalShow() { // 显示 分配权限 弹框
            console.log('this.detail:', this.detail)
            this.getAuthorityListAll();
            this.dialogVisible = true;
        },
        handleModalClose() { // 关闭 分配权限 弹框
            this.dialogVisible = false;
            this.authList = Core.Utils.deepCopy(AUTH_LIST)
        },

        handleConfirm() { // 确认 分配权限
            Core.Api.Authority.saveRoleAuthority(
                this.detail.id,
                this.authIdsList.join(','),
            ).then(() => {
                this.$message.success('权限分配成功');
                this.handleModalClose();
                this.$emit('success');
            }).catch(err => {
                console.log("handleConfirm -> err", err)
            })
        },
        handleSelectChange(val) { // 权限选择改变
            let list = []
            for (const item of this.authList) {
                list.push(...item.select)
            }
            this.authIdsList = list
            let education_manage = [1,2,3,4,5,6,7,8,9,10,11,13,14,17]
            if (!this.authIdsList.includes(15)) {
                for (let i = 0; i < education_manage.length; i++) {
                    if (this.authIdsList.indexOf(education_manage[i]) !== -1) {
                        this.authIdsList.push(15)
                        this.authList[0].select.push(15)
                        break;
                    }
                }
            }
        },
    }
}
</script>

<style lang='scss'>
.AllotAuthority {
    display: inline-block;
    text-align: center;
}
.allot-authority-dialog {
    .el-dialog__body {
        max-height: 50vh;
        overflow-y: auto;
    }
    .dialog-content {
        margin-bottom: -10px;
        position: relative;
    }
    .form-item {
        display: flex;
        align-items: flex-start;
        .key {
            width: 100px;
            font-size: 16px;
            font-weight: bold;
            text-align: left;
        }
        .value {
            width: calc(100% - 100px);
            margin-bottom: 10px;
            margin-top: 5px;
            padding: 0;
            // margin: 0;
        }
    }
    .authority-item {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .el-checkbox {
            width: calc(100% / 3);
            margin: 0 0 7px 0;
            display: inline-flex;
            align-items: center;
            .el-checkbox__input {
                line-height: 16px;
                width: 26px;
                text-align: right;
            }
            .el-checkbox__label {
                line-height: 16px;
                width: calc(100% - 26px);
                box-sizing: border-box;
                text-align: left;
                @include ellipsis;
                display: inline-block;
            }
        }
    }
}
</style>